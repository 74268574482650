import { IconButton } from '@mui/material';
import { Tooltip } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

const LPDVIconButtons = ({ treatment, setShowModal, setModalType, setActiveTreatmentId, iconButtons , fromSoapNote=false, setActiveTreatment=()=>{}, fetchAllSupplements }) => {
  


  

  
  const handleOpenModal = async (type) => {
    try {
      if (type === "Supplements") {
        const updatedTreatment = await fetchAllSupplements(treatment);
  
        if (!updatedTreatment) {
          console.error("Error: updatedTreatment is undefined or null");
          return;
        }
  
        setActiveTreatment(updatedTreatment);  
        setActiveTreatmentId(updatedTreatment._id);
        setModalType("Supplements");
        setTimeout(()=>{
          setShowModal(true);
        },1000)
      } else {
        setActiveTreatment(treatment);
        setActiveTreatmentId(treatment?._id);
        setModalType(type);
        setShowModal(true);
      }
    } catch (error) {
      console.error("There was an error fetching the order:", error);
    }
  };

  const getTooltipContent = (labs, dispensed, procedures, supplements) => (
    <div>
      {labs.length > 0 && (
        <div className="mb-4">
          <strong>Labs:</strong>
          {labs.map((lab) => (
            <div key={lab._id} className="my-2">
              {lab.test_name}
            </div>
          ))}
        </div>
      )}
      {dispensed.length > 0 && (
        <div className={`mb-4 ${labs.length > 0 ? "mt-4" : ""}`}>
          <strong>Dispensed:</strong>
          {dispensed.map((item) => (
            <div key={item._id} className="my-2">
              {item.name} - Quantity: {item.quantity} - Dosage:{" "}
              {item.selectedField}
            </div>
          ))}
        </div>
      )}
      {supplements.length > 0 && (
        <div className={`mb-4 ${labs.length > 0 ? "mt-4" : ""}`}>
          <strong>Supplements:</strong>
          {supplements.map((item) => (
            <div key={item._id} className="my-2">
              {item.name} - Quantity: {item.quantity}
            </div>
          ))}
        </div>
      )}
      {procedures.length > 0 && (
        <div
          className={`mb-4 ${
            labs.length > 0 || dispensed.length > 0 ? "mt-4" : ""
          }`}
        >
          <strong>Procedures:</strong>
          {procedures.map((procedure) => (
            <div key={procedure._id} className="my-2">
              {procedure.procedure_name}
              {procedure.selectedField &&
                ` - Selected: ${procedure.selectedField}`}
              {procedure.note && ` - Note: ${procedure.note}`}
            </div>
          ))}
        </div>
      )}
    </div>
  );

  const getCheckedLabs = (userLabs) => {
    return userLabs?.filter((lab) => lab.checked);
  };
  const getDispensedItemsWithSelectedField = (userdispensed) => {
    return userdispensed?.filter((item) => item.selectedField);
  };
  const getRelevantProcedures = (userProcedures) => {
    return userProcedures?.filter(
      (procedure) => procedure.checked || procedure.selectedField
    );
  };

  const getRelevantSupplements = (userSupplements) => {
    return userSupplements?.filter(
      (supplement) => supplement.quantity > 0
    );
  };

  const tooltipLabs = getCheckedLabs(treatment.userLabs);
  const tooltipDispensed = getDispensedItemsWithSelectedField(
    treatment?.userdispensed
  );
  const tooltipProcedures = getRelevantProcedures(
    treatment?.userProcedures
  );
  const tooltipSupplements = getRelevantSupplements(
    treatment?.userSupplements
  );

  const [showTooltipLabs, setShowTooltipLabs] = useState(false);
  const [showTooltipProcedures, setShowTooltipProcedures] = useState(false);
  const [showTooltipDispensed, setShowTooltipDispensed] = useState(false);
  const [showTooltipSupplements, setShowTooltipSupplements] = useState(false);

  return (
    <div className="flex items-center">
        {treatment.type==="Treatment"&&<div className="bg-blue-400 p-1 mx-1 rounded-xl"></div>}
        {!fromSoapNote && treatment.type!=="Treatment"&&<div className="bg-orange-400 p-1 mx-1 rounded-xl"></div>}
      {iconButtons.includes("L")&&<Tooltip
        onVisibleChange={(visible) => setShowTooltipLabs(visible)}
        visible={showTooltipLabs}
        title={getTooltipContent(tooltipLabs, [], [], [])}
      >
        <IconButton
          size="small"
          onClick={() => handleOpenModal("Labs")}
          aria-label="labs"
          sx={{
            width: 24,
            height: 24,
            fontSize: "0.9rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: treatment?.userLabs?.some(
              (lab) => lab.checked
            )
              ? "#b9f6ca"
              : "#e3f2fd",
            margin: "2px",
            color: "black",
          }}
        >
          L
        </IconButton>
      </Tooltip>}

      {iconButtons.includes("P")&&<Tooltip
        onVisibleChange={(visible) => setShowTooltipProcedures(visible)}
        visible={showTooltipProcedures}
        title={getTooltipContent([], [], tooltipProcedures, [])}
      >
        <IconButton
          size="small"
          onClick={() => handleOpenModal("Procedures")}
          aria-label="procedures"
          sx={{
            width: 24,
            height: 24,
            fontSize: "0.9rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: treatment?.userProcedures?.some(
              (procedure) =>
                (procedure.selectedField &&
                  procedure.selectedField.trim().length > 0) ||
                procedure.checked
            )
              ? "#b9f6ca"
              : "#90caf9",
            margin: "2px",
            color: "black",
          }}
        >
          P
        </IconButton>
      </Tooltip>}

      {iconButtons.includes("D")&&<Tooltip
        onVisibleChange={(visible) => setShowTooltipDispensed(visible)}
        visible={showTooltipDispensed}
        title={getTooltipContent([], tooltipDispensed, [], [])}
      >
        <IconButton
          size="small"
          onClick={() => handleOpenModal("Dispensed")}
          aria-label="dispense"
          sx={{
            width: 24,
            height: 24,
            fontSize: "0.9rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: treatment?.userdispensed?.some(
              (dispensed) =>
                dispensed.selectedField &&
                dispensed.selectedField.trim().length > 0
            )
              ? "#b9f6ca"
              : "#42a5f5",
            margin: "2px",
            color: "black",
          }}
        >
          D
        </IconButton>
      </Tooltip>}

      {iconButtons.includes("S")&&<Tooltip
        onVisibleChange={(visible) => setShowTooltipSupplements(visible)}
        visible={showTooltipSupplements}
        title={getTooltipContent([], [], [], tooltipSupplements)}
      >
        <IconButton
          size="small"
          onClick={() => handleOpenModal("Supplements")}
          aria-label="supplement"
          sx={{
            width: 24,
            height: 24,
            fontSize: "0.9rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: treatment?.userSupplements?.some(
              (supplement) => supplement.quantity>0 )
              ? "#00cc00"
              : "#f0ad4e",
            margin: "2px",
            color: "black",
          }}
        >
          S
        </IconButton>
      </Tooltip>}

      {iconButtons.includes("V")&&<IconButton
        size="small"
        onClick={() => handleOpenModal("Vitals")}
        aria-label="dispense"
        sx={{
          width: 24,
          height: 24,
          fontSize: "0.9rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor:
            treatment?.vitals?.length > 0 ? "#81e984" : "#1e88e5",
          margin: "2px",
          color: "black",
        }}
      >
        V
      </IconButton>}
    </div>
  );
};

export default LPDVIconButtons;
