import React, { useEffect, useRef, useState } from 'react';
import { Modal, Checkbox, Button } from 'antd';
import axios from 'axios';
import moment from 'moment';
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
import signatureImage from "../../../../Assets/signature.jpg";

import { Dialog, DialogContent, DialogTitle } from '@mui/material';

const PrintModal = ({ isOpen, onClose, selectedPatient, encounters = [], soapNotes = {}, formSoapNote = false }) => {
    const [checkboxes, setCheckboxes] = useState({
        subjective: false,
        objective: false,
        assessment: false,
        careplan: false,
        plan: false,
        healthConcerns: false,
        diagnoses: false,
        familyHealthHistory: false,
        pastMedicalHistory: false,
        allergies: false,
        medications: false,
        implantableDevices: false,
        goals: false
    });
    const [emrData, setEMRData] = useState({})
    const [selectedEncounters, setSelectedEncounters] = useState({});
    const [separatePageSections, setSeparatePageSections] = useState([]); // Sections to be printed on a separate page
    const [openPreview, setOpenPreview] = useState(false);
    const [pdfUrl, setPdfUrl] = useState(null);
    const [medicationsCategory, setMedicationsCategory] = useState('all');
    const pdfDocRef = useRef(null);

    const resetCheckboxes=()=>{
        setCheckboxes({
            subjective: false,
            objective: false,
            assessment: false,
            careplan: false,
            plan: false,
            healthConcerns: false,
            diagnoses: false,
            familyHealthHistory: false,
            pastMedicalHistory: false,
            allergies: false,
            medications: false,
            implantableDevices: false,
            goals: false
        })
    }
    const handleDownloadPDF = () => {
        if (pdfDocRef.current) {
            pdfDocRef.current.save("EMR_Data.pdf");
        }
    };

    const fetchEMRData = async () => {
        try {
            const response = await axios.get(`/emr/${selectedPatient._id}`)
            if (response.data) {
                setEMRData(response.data)
            }
            else {
                setEMRData({})
            }
        } catch (error) {
            console.error('Error fetching EMR data', error);
        }
    }

    useEffect(() => {
        if (selectedPatient) {
            fetchEMRData();
        }
    }, [selectedPatient])

    const calculateAge = (dob, date) => {
        if (!dob || !date) return "";
    
        const birthDate = moment(dob).toDate(); 
        const comparedDate = moment(date).toDate(); 
    
        let age = comparedDate.getFullYear() - birthDate.getFullYear();
        const monthDifference = comparedDate.getMonth() - birthDate.getMonth();
    
        if (
          monthDifference < 0 ||
          (monthDifference === 0 && comparedDate.getDate() < birthDate.getDate())
        ) {
          age--;
        }
    
        return age.toString(); 
      };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setCheckboxes((prev) => ({
            ...prev,
            [name]: checked
        }));

        // If checkbox is unchecked, also uncheck the separate page checkbox for that section
        if (!checked) {
            setSeparatePageSections((prev) => prev.filter((item) => item !== name));
        }
    };

    const handleSeparatePageChange = (e, sectionKey) => {
        if (!selectedEncounters[sectionKey] && !checkboxes[sectionKey]) return;
        const { checked } = e.target;
        setSeparatePageSections((prev) =>
            checked
                ? [...prev, sectionKey] 
                : prev.filter((item) => item !== sectionKey)
        );
    };

    const formatAllergies = (allergies) => {
        const { drug, food, environmental, medications } = allergies || {};
        let content = '';

        if (drug) content += `Drug: ${drug}\n`;
        if (food) content += `Food: ${food}\n`;
        if (environmental) content += `Environmental: ${environmental}\n`;
        if (medications) content += `Medications: ${medications}\n`;

        return content;
    };
    // Helper function to format and add Past Medical History data to the content
    const formatPastMedicalHistory = (pastMedicalHistory) => {
        const { majorEvents, ongoingMedicalProblems, preventiveCare, socialHistory, nutritionHistory, developmentHistory } = pastMedicalHistory || {};
        let content = '';

        if (majorEvents) content += `Major Events: ${majorEvents}\n`;
        if (ongoingMedicalProblems) content += `Ongoing Medical Problems: ${ongoingMedicalProblems}\n`;
        if (preventiveCare) content += `Preventive Care: ${preventiveCare}\n`;
        if (socialHistory) content += `Social History: ${socialHistory}\n`;
        if (nutritionHistory) content += `Nutrition History: ${nutritionHistory}\n`;
        if (developmentHistory) content += `Development History: ${developmentHistory}\n`;

        return content;
    };

    

    const handlePrint = () => {
        
        const printContent = document.createElement('div');
        printContent.style.padding = '10px';
        printContent.style.fontFamily = 'Arial, sans-serif';
        printContent.style.fontSize = '12pt';  
        printContent.style.lineHeight = '1.5'; 
    
        let y = 10;
        pdfDocRef.current = printContent;
    

        const renderHeader = (patientInfo) => {
            const headerContent = `
                <div style="display: flex; justify-content: space-between; font-size: 6pt; border-bottom: 1px solid #000; padding-bottom: 10px;">
                    <div style="flex: 1; padding-right: 10px;">
                        <div style="font-weight: normal;">PATIENT:</div>
                        <div style="font-weight: bold;">${patientInfo["Full Name"]}</div>
                        <div>DOB: ${patientInfo?.DOB?moment(patientInfo?.DOB).format("DD-MM-YYYY") : ""}</div>
                        <div>AGE: ${calculateAge(patientInfo?.DOB,moment.utc().format("DD-MM-YYYY"))}</div>
                        <div>SEX: ${patientInfo.sex || "N/A"}</div>
                    </div>
        
                    <div style="flex: 1; text-align: left; padding-left: 10px;">
                        <div style="font-weight: bold;">FACILITY</div>
                        <div>Sunridge Medical</div>
                        <div>T (480) 659-9135</div>
                        <div>F (480) 659-9197</div>
                        <div>14200 N Northsight Blvd 160</div>
                        <div>Scottsdale, AZ 85260</div>
                    </div>

                    <div style="flex: 1; text-align: left; padding-left: 10px;">
                        <div>ENCOUNTER</div>
                        <div>Office Visit</div>
                        <div>NOTE TYPE:</div>
                        <div>SEEN BY:</div>
                        <div>DATE: ${moment.utc().format("DD-MM-YYYY")}</div>
                        <div>AGE AT DOS: ${calculateAge(patientInfo?.DOB,moment.utc().format("DD-MM-YYYY"))}</div>
                        <div style="display:flex; text-align: left; gap:5px;">
                        <div><strong>Signed by:</strong> </div>
                        <div style="font-family: 'GreatVibes', cursive; font-size: 10pt;">
                            <img src="${signatureImage}" alt="Signature" style="width: 100px;"/>
                        </div>
                        </div>
                    </div>
                </div>
            `;
            printContent.innerHTML += headerContent;
        };
        const addPageBreak = () => {
            const pageBreak = document.createElement('div');
            pageBreak.style.pageBreakBefore = 'always';
            printContent.appendChild(pageBreak);
            y = 10; 
        };
    
        const formatField = (title, data) => {
            if(title === 'Allergies'){
                return `<p><strong>${title}</strong> 
                <br/><p> <p style="font-weight:500; margin-left:10px">Drug:</p>  ${data.drug}</p>
                <p> <p style="font-weight:500; margin-left:10px">Food:</p>  ${data.food}</p>
                <p> <p style="font-weight:500; margin-left:10px">Environmental:</p>  ${data.environmental}</p>
                <p> <p style="font-weight:500; margin-left:10px">Medications:</p>  ${data.medications}</p>
                </p>`;
            }
            if(title === 'Past Medical History'){
                return `<p><strong>${title}</strong> 
                <br/><p> <p style="font-weight:500; margin-left:10px">Major Events:</p>  ${data.majorEvents}</p>
                <p> <p style="font-weight:500; margin-left:10px">Ongoing Medical Problems:</p>  ${data.ongoingMedicalProblems}</p>
                <p> <p style="font-weight:500; margin-left:10px">Preventive Care:</p>  ${data.preventiveCare}</p>
                <p> <p style="font-weight:500; margin-left:10px">Social History:</p>  ${data.socialHistory}</p>
                <p> <p style="font-weight:500; margin-left:10px">Nutrition History:</p>  ${data.nutritionHistory}</p>
                <p> <p style="font-weight:500; margin-left:10px">Development History:</p>  ${data.developmentHistory}</p>
                </p>`;
            }
            if (title === 'Medications') {
                let medications=[];
                console.log('************** data', data, "**********", selectedPatient)
                if(medicationsCategory==='all'){
                    medications =[...data,...selectedPatient?.HistoricalSupplements]
                }
                else if(medicationsCategory==='active'){
                    medications =[...data]
                }
                else if(medicationsCategory==='historical'){
                    medications =[...selectedPatient?.HistoricalSupplements]
                }
                return formatArrayField(title, medications);
            }

            else return `<p><strong>${title}:</strong> ${data}</p>`;
        };
    
        const formatArrayField = (title, data) => {
            if (!data || !Array.isArray(data) || data.length === 0) return "";
            return `<p><strong>${title}:</strong><br>${data
                .map(
                    (item) => `- ${item.supplementName} (${item.frequency})`
                )
                .join("<br>")}</p>`;
        };
    
        const formatEncounterContent = (encounter) => {
            return `
                <h3>SOAP Note for ${moment(encounter.createdAt.split('T')[0]).format('MM-DD-YYYY')}</h3>
                <p><strong>Subjective:</strong> ${encounter?.subjective || ""}</p>
                <p><strong>Objective:</strong> ${encounter?.objective || ""}</p>
                <p><strong>Assessment:</strong> ${encounter?.assessment || ""}</p>
                <p><strong>Care Plan:</strong> ${encounter?.careplan || ""}</p>
                <p><strong>Plan:</strong> ${encounter?.plan?.note || ""}</p>
            `;
        };
    
        const sections = [
            { name: "subjective", label: "Subjective", content: soapNotes.subjective || "" },
            { name: "objective", label: "Objective", content: soapNotes.objective || "" },
            { name: "assessment", label: "Assessment", content: soapNotes.assessment },
            { name: "careplan", label: "Care Plan", content: soapNotes.careplan || "" },
            { name: "plan", label: "Plan", content: soapNotes?.planNote || "" },
            { name: "healthConcerns", label: "Health Concerns", content: emrData.healthConcerns || "" },
            { name: "diagnoses", label: "Diagnoses", content: emrData.diagnoses || "" },
            { name: "familyHealthHistory", label: "Family Health History", content: emrData.familyHealthHistory || "" },
            { name: "goals", label: "Goals", content: emrData.goals || "" },
            { name: "allergies", label: "Allergies", content: emrData.allergies || "" },
            { name: "pastMedicalHistory", label: "Past Medical History", content: emrData.pastMedicalHistory || "" },
            { name: "medications", label: "Medications", content: emrData.medications || "" },
            { name: "implantableDevices", label: "Implantable Devices", content: emrData.implantableDevice || "" }
        ];
        
                
        // Call renderHeader before printing the content
        renderHeader(selectedPatient);

        sections.forEach((section) => {
            if (checkboxes[section.name] && !separatePageSections.includes(section.name)) {
                printContent.innerHTML += formatField(section.label, section.content);
            }
        });

        const separateSections = [
            { name: "subjective", label: "Subjective", content: soapNotes.subjective || "" },
            { name: "objective", label: "Objective", content: soapNotes.objective || "" },
            { name: "assessment", label: "Assessment", content: soapNotes.assessment || "" },
            { name: "careplan", label: "Care Plan", content: soapNotes.careplan || "" },
            { name: "plan", label: "Plan", content: soapNotes?.plan?.note || "" },
            { name: "healthConcerns", label: "Health Concerns", content: emrData.healthConcerns || "" }, 
            { name: "diagnoses", label: "Diagnoses", content: emrData.diagnoses || "" },
            { name: "familyHealthHistory", label: "Family Health History", content: emrData.familyHealthHistory || "" },
            { name: "implantableDevice", label: "Implantable Device", content: emrData.implantableDevice || "" },
            { name: "goals", label: "Goals", content: emrData.goals || "" },
            { name: "pastMedicalHistory", label: "Past Medical History", content: formatPastMedicalHistory(emrData.pastMedicalHistory) },
            { name: "allergies", label: "Allergies", content: formatAllergies(emrData.allergies) },
            { name: "medications", label: "Medications", content: emrData.medications || "" }
        ];
    
        separateSections.forEach((section) => {
            if (checkboxes[section.name] && separatePageSections.includes(section.name)) {
                addPageBreak();
                printContent.innerHTML += formatField(section.label, section.content);
            }
        });
    

    


        const updatedSelectedEncounters = Object.fromEntries(
            Object.entries(selectedEncounters).filter(([key, value]) => value === true)
          );
          
        const filteredEncounters = encounters.filter(encounter =>
            updatedSelectedEncounters.hasOwnProperty(encounter._id)
        );
        
        filteredEncounters.forEach((encounter) => {
            const isSeparatePage = separatePageSections.includes(encounter._id);
    
            if (isSeparatePage) addPageBreak();
    
            const encounterContent = formatEncounterContent(encounter);
            printContent.innerHTML += encounterContent;
    
            if (isSeparatePage) addPageBreak();
        });
            
        html2pdf()
        .from(printContent)
        .set({
            margin: 10,
            filename: 'emr.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { dpi: 300, scale: 2 },  
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        })
        .toPdf()
        .get('pdf')
        .then((pdf) => {
            
            pdfDocRef.current = pdf; 
            const pdfBlob = pdf.output('blob');
            const pdfUrl = URL.createObjectURL(pdfBlob);
            setPdfUrl(pdfUrl);
            setOpenPreview(true);
        });
    
    };
    
    
    

    const handlePrintAll = () => {
        
        if (formSoapNote) {
            setCheckboxes((prev) => Object.fromEntries(Object.keys(prev).map(key => [key, true])));
        }
        else {
            setCheckboxes((prev) =>
                Object.fromEntries(
                    Object.entries(prev).map(([key, value]) => [
                        key,
                        ['subjective', 'objective', 'assessment', 'careplan', 'plan'].includes(key) ? value : true,
                    ])
                )
            );
            handleCheckAllEncounters(true);
        }
    };

    useEffect(() => {
        if (formSoapNote && Object.values(checkboxes).every(value => value === true)) {
            handlePrint();
        }
        if (!formSoapNote&&Object.entries(checkboxes).every(([key, value]) => 
            (['subjective', 'objective', 'assessment', 'careplan', 'plan'].includes(key) ? value === false : value === true)
          )) {
            handlePrint();
          }
          
    }, [checkboxes]);

    const handleEncounterCheckboxChange = (id) => {
        setSelectedEncounters((prev) => ({
            ...prev,
            [id]: !prev[id]
        }));
    };

    const handleCheckAllEncounters = (isChecked) => {
        const allEncounters = {};
        encounters.forEach((encounter) => {
            allEncounters[encounter._id] = isChecked;
        });
        setSelectedEncounters(allEncounters);
    };
    
    return (
        <>
            <Modal
                title="Select Fields to Print"
                visible={isOpen}
                onCancel={()=>{
                    onClose()
                    resetCheckboxes()
                    setSeparatePageSections([])
                    setSelectedEncounters({})
                    setMedicationsCategory('all')
                }}
                footer={[
                    <Button key="close" onClick={()=>{
                        onClose()
                        resetCheckboxes()
                        setSeparatePageSections([])
                        setSelectedEncounters({})
                        setMedicationsCategory('all')
                    }}>
                        Close
                    </Button>,
                    <Button key="print" className='!bg-[#111828] hover:!bg-[#3d3d3d] hover:!text-white text-white border-none' onClick={handlePrint}>
                        Print
                    </Button>,
                    <Button key="printAll" className='!bg-[#111828] hover:!bg-[#3d3d3d] hover:!text-white text-white border-none' onClick={handlePrintAll}>
                        Print All
                    </Button>,
                ]}
            >
                <div className=" relative w-full flex flex-row items-start justify-stretch gap-4 h-auto">
                    {/* Left Section */}
                    <div className="flex flex-col justify-center gap-4 w-3/6">
                        <div className="flex flex-col gap-3">
                            <div className="flex flex-col items-start gap-3">
                                <Checkbox
                                    name="healthConcerns"
                                    checked={checkboxes.healthConcerns}
                                    onChange={handleCheckboxChange}
                                >
                                    Health Concerns
                                </Checkbox>                                
                                {checkboxes["healthConcerns"] && <Checkbox
                                    style={{ marginLeft: '10px' }}
                                    disabled={!checkboxes["healthConcerns"]}
                                    checked={separatePageSections.includes("healthConcerns")}
                                    onChange={(e) => handleSeparatePageChange(e, "healthConcerns")}
                                >
                                    Print on Separate Page
                                </Checkbox>}
                                <Checkbox
                                    name="diagnoses"
                                    checked={checkboxes.diagnoses}
                                    onChange={handleCheckboxChange}
                                >
                                    Diagnoses
                                </Checkbox>
                                {checkboxes["diagnoses"] && <Checkbox
                                    style={{ marginLeft: '10px' }}
                                    disabled={!checkboxes["diagnoses"]}
                                    checked={separatePageSections.includes("diagnoses")}
                                    onChange={(e) => handleSeparatePageChange(e, "diagnoses")}
                                >
                                    Print on Separate Page
                                </Checkbox>}
                                <Checkbox
                                    name="familyHealthHistory"
                                    checked={checkboxes.familyHealthHistory}
                                    onChange={handleCheckboxChange}
                                >
                                    Family Health History
                                </Checkbox>                                
                                {checkboxes["familyHealthHistory"] && <Checkbox
                                    style={{ marginLeft: '10px' }}
                                    disabled={!checkboxes["familyHealthHistory"]}
                                    checked={separatePageSections.includes("familyHealthHistory")}
                                    onChange={(e) => handleSeparatePageChange(e, "familyHealthHistory")}
                                >
                                    Print on Separate Page
                                </Checkbox>}
                            </div>
                            <Checkbox
                                name="pastMedicalHistory"
                                checked={checkboxes.pastMedicalHistory}
                                onChange={handleCheckboxChange}
                            >
                                Past Medical History
                            </Checkbox>
                            {checkboxes["pastMedicalHistory"] && <Checkbox
                                style={{ marginLeft: '10px' }}
                                disabled={!checkboxes["pastMedicalHistory"]}
                                checked={separatePageSections.includes("pastMedicalHistory")}
                                onChange={(e) => handleSeparatePageChange(e, "pastMedicalHistory")}
                            >
                                Print on Separate Page
                            </Checkbox>}
                            <Checkbox
                                name="allergies"
                                checked={checkboxes.allergies}
                                onChange={handleCheckboxChange}
                            >
                                Allergies
                            </Checkbox>                            
                            {checkboxes["allergies"] && <Checkbox
                                style={{ marginLeft: '10px' }}
                                disabled={!checkboxes["allergies"]}
                                checked={separatePageSections.includes("allergies")}
                                onChange={(e) => handleSeparatePageChange(e, "allergies")}
                            >
                                Print on Separate Page
                            </Checkbox>}

                            <Checkbox
                                name="medications"
                                checked={checkboxes.medications}
                                onChange={handleCheckboxChange}
                            >
                                Medications
                            </Checkbox>
                            {checkboxes["medications"] && <div className='w-full flex border-[1px] border-gray-100  rounded-md px-[10px] py-[5px]'>
                                <Checkbox
                                    disabled={!checkboxes["medications"]}
                                    checked={medicationsCategory==='active'}
                                    onChange={(e) => setMedicationsCategory("active")}
                                >
                                    Active
                                </Checkbox>
                                <Checkbox
                                    disabled={!checkboxes["medications"]}
                                    checked={medicationsCategory==='historical'}
                                    onChange={(e) => setMedicationsCategory("historical")}
                                >
                                    Historical
                                </Checkbox>
                                <Checkbox
                                    disabled={!checkboxes["medications"]}
                                    checked={medicationsCategory==='all'}
                                    onChange={(e) => setMedicationsCategory("all")}
                                >
                                    All
                                </Checkbox>
                            </div>}

                            {checkboxes["medications"] && <Checkbox
                                style={{ marginLeft: '10px' }}
                                disabled={!checkboxes["medications"]}
                                checked={separatePageSections.includes("medications")}
                                onChange={(e) => handleSeparatePageChange(e, "medications")}
                            >
                                Print on Separate Page
                            </Checkbox>}
                            <Checkbox
                                name="implantableDevices"
                                checked={checkboxes.implantableDevices}
                                onChange={handleCheckboxChange}
                            >
                                Implantable Devices
                            </Checkbox>
                            { }
                            {checkboxes["implantableDevices"] && <Checkbox
                                style={{ marginLeft: '10px' }}
                                disabled={!checkboxes["implantableDevices"]}
                                checked={separatePageSections.includes("implantableDevices")}
                                onChange={(e) => handleSeparatePageChange(e, "implantableDevices")}
                            >
                                Print on Separate Page
                            </Checkbox>}
                            <Checkbox
                                name="goals"
                                checked={checkboxes.goals}
                                onChange={handleCheckboxChange}
                            >
                                Goals
                            </Checkbox>
                            {checkboxes["goals"] && <Checkbox
                                style={{ marginLeft: '10px' }}
                                disabled={!checkboxes["goals"]}
                                checked={separatePageSections.includes("goals")}
                                onChange={(e) => handleSeparatePageChange(e, "goals")}
                            >
                                Print on Separate Page
                            </Checkbox>}
                        </div>
                    </div>

                    {/* Center Divider */}
                    <div className="w-[1px] bg-gray-500 h-auto"></div>
                    {/* Encounters Section */}
                    {encounters.length > 0 && <div className="flex flex-col items-start gap-3 pb-4">
                        <strong>Encounters:</strong>
                        <div className="flex flex-col items-start gap-3 h-80 overflow-y-auto pr-5">
                        {encounters.map((encounter) => (
                            <>
                                <Checkbox
                                    key={encounter._id}
                                    checked={selectedEncounters[encounter._id] || false}
                                    onChange={() => handleEncounterCheckboxChange(encounter._id)}
                                >
                                    {moment(encounter.createdAt.split('T')[0]).format('MM-DD-YYYY')}
                                </Checkbox>
                                {selectedEncounters[encounter._id] && (
                                    <Checkbox
                                        style={{ marginLeft: '10px' }}
                                        checked={separatePageSections.includes(encounter._id)}
                                        onChange={(e) => handleSeparatePageChange(e, encounter._id)}
                                    >
                                        Print on Separate Page
                                    </Checkbox>
                                )}
                            </>
                        ))}
                        </div>
                    </div>}
                    {/* Right Section */}
                    {formSoapNote && <div className="flex flex-col items-start gap-3 w-3/6">
                        <Checkbox
                            name="subjective"
                            checked={checkboxes.subjective}
                            onChange={handleCheckboxChange}
                        >
                            Subjective
                        </Checkbox>
                        {checkboxes["subjective"] && <Checkbox
                            style={{ marginLeft: '10px' }}
                            disabled={!checkboxes["subjective"]}
                            checked={separatePageSections.includes("subjective")}
                            onChange={(e) => handleSeparatePageChange(e, "subjective")}
                        >
                            Print on Separate Page
                        </Checkbox>}
                        <Checkbox
                            name="objective"
                            checked={checkboxes.objective}
                            onChange={handleCheckboxChange}
                        >
                            Objective
                        </Checkbox>
                        {checkboxes["objective"] && <Checkbox
                            style={{ marginLeft: '10px' }}
                            disabled={!checkboxes["objective"]}
                            checked={separatePageSections.includes("objective")}
                            onChange={(e) => handleSeparatePageChange(e, "objective")}
                        >
                            Print on Separate Page
                        </Checkbox>}
                        <Checkbox
                            name="assessment"
                            checked={checkboxes.assessment}
                            onChange={handleCheckboxChange}
                        >
                            Assessment
                        </Checkbox>
                        {checkboxes["assessment"] && <Checkbox
                            style={{ marginLeft: '10px' }}
                            disabled={!checkboxes["assessment"]}
                            checked={separatePageSections.includes("assessment")}
                            onChange={(e) => handleSeparatePageChange(e, "assessment")}
                        >
                            Print on Separate Page
                        </Checkbox>}
                        <Checkbox
                            name="careplan"
                            checked={checkboxes.careplan}
                            onChange={handleCheckboxChange}
                        >
                            Care Plan
                        </Checkbox>
                        {checkboxes["careplan"] && <Checkbox
                            style={{ marginLeft: '10px' }}
                            disabled={!checkboxes["careplan"]}
                            checked={separatePageSections.includes("careplan")}
                            onChange={(e) => handleSeparatePageChange(e, "careplan")}
                        >
                            Print on Separate Page
                        </Checkbox>}
                        <Checkbox
                            name="plan"
                            checked={checkboxes.plan}
                            onChange={handleCheckboxChange}
                        >
                            Plan
                        </Checkbox>
                        {checkboxes["plan"] && <Checkbox
                            style={{ marginLeft: '10px' }}
                            disabled={!checkboxes["plan"]}
                            checked={separatePageSections.includes("plan")}
                            onChange={(e) => handleSeparatePageChange(e, "plan")}
                        >
                            Print on Separate Page
                        </Checkbox>}
                    </div>}
                </div>

            </Modal>
            <div>
                <Dialog
                    open={openPreview}
                    onClose={() => {
                        setOpenPreview(false);
                    }}
                    maxWidth="lg"
                    fullWidth
                    PaperProps={{
                        style: { height: "100%", padding: "10px 20px" },
                    }}
                >
                    <DialogTitle>
                        PDF Preview
                        <button
                            className="py-2 px-4 text-sm bg-blue-500 hover:bg-gray-600 text-white rounded-md focus:outline-none"
                            onClick={handleDownloadPDF}
                            style={{ float: "right" }}
                        >
                            Download PDF
                        </button>
                    </DialogTitle>
                    <DialogContent
                        dividers
                        style={{ height: "calc(100% - 64px)" }}
                    >
                        {pdfUrl && (
                            <iframe
                                src={pdfUrl}
                                width="100%"
                                height="100%"
                                title="PDF Preview"
                                style={{ border: "none" }}
                            />
                        )}
                    </DialogContent>
                </Dialog>
            </div>
        </>

    );
};

export default PrintModal;
