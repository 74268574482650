// index.js

import { Button, message } from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

import { PlusOutlined, ReloadOutlined, WarningFilled } from "@ant-design/icons";
import { Warning } from "@mui/icons-material";
import { Box, Paper, Skeleton, Typography } from "@mui/material";
import { yellow } from "@mui/material/colors";
import "antd/dist/reset.css";
import dayjs from "dayjs";
import moment from "moment";
import { ToastContainer, toast as toastifyToast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LPDPopup from "../../Components/LPDPopup";
import TreatmentTemplates from "../TreatmentViewDisplay/TreatmentTemplates";
import {
  CheckboxCellRenderer,
  CheckboxHeader,
  DateCellRenderer,
  DelDupRenderer,
  DropdownCellRenderer,
  LPDCellRenderer,
  NoteCellRenderer,
  QuantityCellRenderer,
} from "./Components/CellRenderers";
import EventsModal from "./Components/EventsModal";
import PatientSearch from "./Components/PatientSearch";
import SupplementsModal from "./Components/SupplementsModal";
import TableFilters from "./Components/TableFilters";
import TreatmentTable from "./Components/TreatmentTable";
import UpdatePatient from "./Components/UpdatePatient";
import VitalsReadOnlyView from "./Components/VitalsReadOnlyView";
import { useParams } from "react-router-dom";

const TreatmentView = () => {
  const [patients, setPatients] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [filter, setFilter] = useState("all");
  const [allColumnDefs, setAllColumnDefs] = useState({});
  const [template, setTemplate] = useState(null);
  const [selectedDate, setSelectedDate] = useState(
    dayjs(moment().format("YYYY/MM/DD"), "YYYY/MM/DD")
  );
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedPatientId, setSelectedPatientId] = useState(undefined);
  const [orders, setOrders] = useState([]);
  const previousPatientId = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [activeTreatmentId, setActiveTreatmentId] = useState(null);
  const [activeTreatment, setActiveTreatment] = useState({});
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [updatedEvents, setUpdatedEvents] = useState([]);
  const [checkboxes, setCheckboxes] = useState({
    activeLabs: false,
    activeProcedures: false,
    activeDispensed: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [assigneeOptions, setassigneeOptions] = useState([]);
  const [roomOptions, setRoomOptions] = useState([]);
  const [columnsWidth, setColumnsWidth] = useState({
    lab: "",
    procedure: "",
    dispensed:""
  });
  const [visibility, setVisibility] = useState({
    showNotes: true,
    showLabs: true,
    showProcedures: true,
    showDispensed: true,
    hideAllEmptyColumns: true,
  });

  const { patientId } = useParams(); // Get the patientId (customerId) from the URL
  const [objectId, setObjectId] = useState(null);
  const [hasActiveLPD, setHasActiveLPD] = useState(true);



  useEffect(() => {
    fetchAssigneeOptions();
    fetchRoomOptions();
    fetchTableSettings();
  }, []);
  const controllerRef = useRef(null);

  const handleSearch = async (value) => {
    setHighlightedIndex(0);
    if (controllerRef.current) {
      controllerRef.current.abort();
    }

    const controller = new AbortController();
    controllerRef.current = controller;

    if (value) {
      try {
        const response = await axios.get(
          `/api/patients/search?query=${value}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
            signal: controller.signal,
          }
        );
        setPatients(response.data);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Previous request canceled:", error.message);
        } else {
          console.error("Error fetching patients:", error);
        }
      }
    } else {
      setPatients([]);
    }
  };

  // Handle Search for Patient Using Patient ID

  
  // This function will fetch the ObjectId from the backend using patientId
  const fetchPatientProfile = async (patientId) => {
    try {
      const response = await axios.get(
        `/api/patient-profiles/get-object-id/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
  
      if (response.data && response.data.objectId) {
        // Set the fetched Object ID into state
        setObjectId(response.data.objectId); // Store Object ID
        console.log("*****************",response.data)
      } else {
        console.log("No Object ID found for this patient.");
      }
    } catch (error) {
      console.error("Error fetching Object ID:", error.message);
    }
  };
  
  // This will run once when the patientId is available in the URL
  useEffect(() => {
    if (patientId) {
      fetchPatientProfile(patientId); // Fetch patient profile when patientId is available
    }
  }, [patientId]);
  
  // Whenever Object ID is fetched and set, call handleSelectPatient
  useEffect(() => {
    if (objectId) {
      handleSelectPatient(objectId); // Trigger the patient select logic using the Object ID
    }
  }, [objectId]); // Only run when objectId changes
  
  // Modified handleSelectPatient function to match the new logic
  const handleSelectPatient = async (patientId) => {
    try {
      const patientResponse = await axios.get(
        `/api/patients/${patientId}`, // Fetching using ObjectId
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      if (patientResponse.status === 200) {
        setSelectedPatient(patientResponse.data);
        setSelectedPatientId(patientResponse.data["Customer ID"]);
  
        // Now fetch treatments using the patient Customer ID
        await fetchTreatments(patientResponse.data["Customer ID"]);
      }
    } catch (error) {
      console.error("Error fetching patient data:", error);
      toastifyToast.error(`Failed to fetch patient data: ${error.message}`);
    }
  };
  
  

  const handleUpdateOrder = async (orderId, updateInfo, newValue) => {
    try {
      const url = `/api/patient-orders/${orderId}`;
      const payload = {
        type: updateInfo.type,
        valueType: updateInfo.valueType,
        name: updateInfo.name,
        value: newValue,
      };

      const response = await axios.put(url, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      });

      if (response.status === 200) {
        console.log(`Order ${orderId} updated successfully`);
        let type = updateInfo.name || updateInfo.valueType;
        toastifyToast.success(`${type} updated successfully`);
        // Optionally, you can update the local state here if needed
        setOrders((prevState) => {
          return prevState.map((order) => {
            if (order._id === orderId) {
              return { ...order, ...response.data.order };
            } else {
              return order;
            }
          });
        });
        // await fetchTreatments(selectedPatient["Customer ID"]);
      } else {
        throw new Error(`Failed to update order ${orderId}`);
      }
    } catch (error) {
      console.error("Error updating order:", error);

      // Check if the error response contains the specific message
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "Error updating order" &&
        error.response.data.error ===
          "You cannot modify this as it has been finalized."
      ) {
        toastifyToast.error(
          "This order has been finalized and cannot be modified.",
          {
            icon: "🔒",
          }
        );
      } else {
        // For all other errors
        toastifyToast.error(
          `Failed to update order: ${
            error.response?.data?.error || error.message
          }`
        );
      }
    }
  };

  const createNewOrder = async (type) => {
    if (!selectedPatient) {
      toastifyToast.error("Please select a patient first");
      return;
    }
    try {
      const response = await axios.post(
        `/api/patient/order/new/${selectedPatient["Customer ID"]}/${type}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        toastifyToast.success(`New ${type.toLowerCase()} created successfully`);
        //Append treatment to the row data
        const newRowData = [
          ...rowData,
          {
            orderId: response.data._id,
            date: response.data.date,
            customName: response.data.custom || "",
            type: response.data.type || "Order",
            review: response.data.review || false,
            status: response.data.status || "",
            assignees: response.data.assignees || [],
            room: response.data.room || "",
            note: response.data.note || "",
          },
        ];
        setRowData(newRowData);
        setOrders((prevState) => {
          return [
            { ...response.data, orderId: response.data._id },
            ...prevState,
          ];
        });
      } else {
        throw new Error(`Failed to create new ${type.toLowerCase()}`);
      }
    } catch (error) {
      console.error(`Error creating new ${type.toLowerCase()}:`, error);
      toastifyToast.error(
        `Failed to create new ${type.toLowerCase()}: ${error.message}`
      );
    }
  };

  const handleCellValueChanged = (params) => {
    const orderId = params.data.orderId;
    const columnId = params.column.colId;
    const newValue = params.newValue;

    // Helper function to determine the update type and value type
    const getUpdateInfo = (columnId) => {
      if (columnId?.startsWith("procedure_")) {
        const parts = columnId.split("_");
        const valueType = parts[parts.length - 1];
        const procedureName = parts
          .slice(
            1,
            valueType === "note" || valueType === "customDosage"
              ? -1
              : undefined
          )
          .join(" ");
        return {
          type: "userProcedures",
          valueType:
            valueType === "customDosage"
              ? "customDosage"
              : valueType === "note"
              ? "note"
              : typeof newValue === "boolean"
              ? "checkbox"
              : "dosage",
          name: procedureName,
        };
      } else if (columnId?.startsWith("lab_")) {
        return {
          type: "userLabs",
          valueType: "checkbox",
          name: columnId.split("_").slice(1).join(" "),
        };
      } else if (columnId?.startsWith("dispensed_")) {
        const parts = columnId.split("_");
        const valueType = parts[parts.length - 1];
        const dispensedName = parts
          .slice(
            1,
            valueType === "quantity" || valueType === "note" ? -1 : undefined
          )
          .join(" ");
        return {
          type: "userdispensed",
          valueType:
            valueType === "quantity"
              ? "quantity"
              : valueType === "note"
              ? "note"
              : "dosage",
          name: dispensedName,
        };
      }
      return { type: "common", valueType: columnId };
    };

    const updateInfo = getUpdateInfo(columnId);

    if (updateInfo.type === "common") {
      switch (columnId) {
        case "date":
          const formattedDate = formatDate(newValue);
          console.log(`Order ID: ${orderId}, Updated Date: ${formattedDate}`);
          break;
        case "customName":
        case "type":
        case "review":
        case "status":
        case "assignees":
        case "room":
        case "note":
          console.log(`Order ID: ${orderId}, Updated ${columnId}: ${newValue}`);
          break;
        default:
          break;
      }
    } else {
      console.log(
        `Order ID: ${orderId}, Type: ${updateInfo.type}, Value Type: ${updateInfo.valueType}, Name: ${updateInfo.name}, New Value: ${newValue}`
      );
    }

    // Call the updateOrder function
    handleUpdateOrder(orderId, updateInfo, newValue);
    // Here you would typically update your backend with the new value
    // updateBackend(orderId, columnId, newValue, updateInfo);
  };

  const fetchTableSettings = () => {
    axios
    .get("/table-settings",{
      headers: {
        Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
      },
    })
      .then((response) => {
        const tableSettings = response?.data[0]
        if (tableSettings?.view === 'treatmentView' && tableSettings?.customizationType === "width") {
          let columns = {};
          tableSettings?.columns.forEach(column => {
            columns[column?.columnName] = column?.columnWidth;
          });
          setColumnsWidth(columns)
        }
    })
    .catch((error) => {
      message.error("An error occurred");
    });
}

  useEffect(() => {
    console.log("columns width", columnsWidth)
  },[columnsWidth])

  const abortController = useRef(null);

  const fetchTreatments = async (patientId) => {
    if (
      abortController.current &&
      previousPatientId.current &&
      patientId !== previousPatientId.current
    ) {
      abortController.current.abort();
    }
  
    if (
      !abortController.current || 
      (patientId !== previousPatientId.current) 
    ) {
      abortController.current = new AbortController();
    }

    const signal = abortController.current.signal;
    try {
      if (patientId !== previousPatientId.current) {
        setIsLoading(true); 
      }
      const ordersResponse = await axios.get(
        `/api/patient-orders/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
          signal
        }
      );
      if (ordersResponse.status === 200) {
        console.log("********** Response", ordersResponse);
        if (ordersResponse.data.length === 0) {
          setOrders([]);
          setRowData([]);
          if ( patientId !== previousPatientId.current){

            toastifyToast.success("Treatments fetched successfully");
            previousPatientId.current=patientId
          }
          setIsLoading(false);
        } else {
          let filteredOrders = ordersResponse.data.map((order) => {
            return { ...order, orderId: order._id };
          });
          setOrders(filteredOrders);
          if ( patientId !== previousPatientId.current) {
            toastifyToast.success("Treatments fetched successfully");
            previousPatientId.current=patientId
          }
          setIsLoading(false);
        }
      }
    } catch (error) {
      if (!axios.isCancel(error) || error.name !== "CanceledError") {  
        console.error("Error fetching treatment data:", error);
        toastifyToast.error(`Failed to fetch treatments: ${error.message}`);
        setIsLoading(false);
      } 
    }
  };

  const getTooltipContent = (labs, dispensed, procedures) => (
    <div>
      {labs.length > 0 && (
        <div className="mb-4">
          <strong>Labs:</strong>
          {labs.map((lab) => (
            <div key={lab._id} className="my-2">
              {lab.test_name}
            </div>
          ))}
        </div>
      )}
      {dispensed.length > 0 && (
        <div className={`mb-4 ${labs.length > 0 ? "mt-4" : ""}`}>
          <strong>Dispensed:</strong>
          {dispensed.map((item) => (
            <div key={item._id} className="my-2">
              {item.name} - Quantity: {item.quantity} - Dosage:{" "}
              {item.selectedField}
            </div>
          ))}
        </div>
      )}
      {procedures.length > 0 && (
        <div
          className={`mb-4 ${
            labs.length > 0 || dispensed.length > 0 ? "mt-4" : ""
          }`}
        >
          <strong>Procedures:</strong>
          {procedures.map((procedure) => (
            <div key={procedure._id} className="my-2">
              {procedure.procedure_name}
              {procedure.selectedField &&
                ` - Selected: ${procedure.selectedField}`}
              {procedure.note && ` - Note: ${procedure.note}`}
            </div>
          ))}
        </div>
      )}
    </div>
  );

  const fetchAssigneeOptions = async () => {
    console.log("Fetching assignee options");
    axios
      .get("/get-Assignee-options", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      })
      .then((response) => {
        setassigneeOptions(response.data[0].name);
      })
      .catch((error) => {
        console.error("There was an error fetching the room options:", error);
      });
  };

  const fetchRoomOptions = async () => {
    console.log("Fetching room options");
    axios
      .get("/get-room-options", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      })
      .then((response) => {
        setRoomOptions(response.data[0].name);
      })
      .catch((error) => {
        console.error("There was an error fetching the room options:", error);
      });
  };

  const handleRefresh = async () => {
    if (selectedPatient) {
      await fetchTreatments(selectedPatient["Customer ID"]);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };
  useEffect(() => {
    setHasActiveLPD(true)
    console.log("useEffect Called", visibility.showNotes);
    let filteredOrders = orders;

    if (checkboxes.activeDispensed) {
      filteredOrders = filteredOrders.filter((order) =>
        order.userdispensed.some((item) => item.quantity > 0)
      );
    }

    if (checkboxes.activeLabs) {
      filteredOrders = filteredOrders.filter((order) =>
        order.userLabs.some((item) => item.checked)
      );
    }

    if (checkboxes.activeProcedures) {
      filteredOrders = filteredOrders.filter((order) =>
        order.userProcedures.some((item) => item.checked)
      );
    }

    if(filteredOrders.length === 0){
      setHasActiveLPD(false)
    }
    processOrdersData(filteredOrders, visibility.hideAllEmptyColumns);
    console.log("Order updated", orders);
    
  }, [orders, checkboxes, visibility]);

  const processOrdersData = (orders, hideEmptyColumns = false) => {
    if (orders?.length === 0) return;

    const sampleOrder = orders[0];
    let commonColumns = [
      { field: "orderId", headerName: "Order ID", hide: true },
      {
        field: "date",
        pinned: "left",
        headerName: "Date",
        cellRenderer: DateCellRenderer,
        valueFormatter: (params) => formatDate(params.value),
        minWidth: 160,
      },
      {
        field: "customName",
        headerName: "Custom Name",
        cellRenderer: NoteCellRenderer,
        minWidth: 160,
      },
      {
        field: "type",
        headerName: "Type",
        cellRenderer: DropdownCellRenderer,
        cellRendererParams: { values: ["Order", "Treatment"] },
        minWidth: 160,
      },
      {
        field: "review",
        headerName: "Final",
        cellRenderer: CheckboxCellRenderer,
        cellRendererParams: { from: "review" },
      },
      {
        field: "status",
        headerName: "Status",
        cellRenderer: DropdownCellRenderer,
        cellRendererParams: { values: ["scheduled", "arrived", "completed"] },
        minWidth: 160,
      },
      {
        field: "assignees",
        headerName: "Assignees",
        cellRenderer: DropdownCellRenderer,
        cellRendererParams: { values: assigneeOptions, from: "assignees" }, // We'll populate this later
        minWidth: 160,
      },
      {
        field: "room",
        headerName: "Room",
        cellRenderer: DropdownCellRenderer,
        cellRendererParams: { values: roomOptions }, // We'll populate this later
        minWidth: 160,
      },
      {
        field: "note",
        headerName: "Note",
        cellRenderer: NoteCellRenderer,
        cellRendererParams: (params) => ({
          treatment: orders?.find((order) => order._id === params.data.orderId),
          selectedDate,
          fromTreatmentNote:true,
        }),
        minWidth: 160,
      },
      {
        field: "del/dup",
        headerName: "Del / Dup",
        cellRenderer: DelDupRenderer,
        cellRendererParams: (params) => ({
          treatment: orders?.find((order) => order._id === params.data.orderId),
          orders,
          setOrders,
          selectedDate,
          setUpdatedEvents,
          updatedEvents,
          isPopoverOpen,
          setIsPopoverOpen,
        }),
        minWidth: 120,
      },
      {
        field: "lpd",
        headerName: "LPDV",
        cellRenderer: LPDCellRenderer,
        cellRendererParams: (params) => ({
          getTooltipContent,
          treatment: orders?.find((order) => order._id === params.data.orderId),
          patient: selectedPatient,
          setModalType,
          setActiveTreatment,
          setShowModal,
          setActiveTreatmentId,
        }),
        minWidth: 160,
      },
    ];

    let procedureColumns = sampleOrder.userProcedures
      .map((procedure) => {
        const baseColumn = {
          headerName: procedure.procedure_name,
          headerTooltip: `${procedure.procedure_name}`,
          headerClass: columnsWidth.procedure==="small"?'rotate-header':"", 
          minWidth: determineWidth(columnsWidth.procedure),
          children: [
            {
              field: `procedure_${procedure.procedure_name.replace(
                /\s+/g,
                "_"
              )}`,
              headerName: "Dosage",
              headerClass: columnsWidth.procedure==="small"?'rotate-header':"",

              headerComponent:
                procedure.dosages && procedure.dosages.length > 0
                  ? undefined
                  : CheckboxHeader,
              headerComponentParams:
                procedure.dosages && procedure.dosages.length > 0
                  ? undefined
                  : {},
              cellRenderer:
                procedure.dosages && procedure.dosages.length > 0
                  ? DropdownCellRenderer
                  : CheckboxCellRenderer,
              cellRendererParams: procedure.dosages
                ? { values: procedure.dosages }
                : undefined,
              minWidth: procedure.dosages && procedure.dosages.length > 0
              ? determineWidth(columnsWidth.procedure):columnsWidth.procedure==="small"?50:100,
            },
          ],
          cellStyle:{textAlign:'center'}

        };

        if (procedure.customDosage) {
          baseColumn.children.push({
            field: `procedure_${procedure.procedure_name.replace(
              /\s+/g,
              "_"
            )}_customDosage`,
            headerName: "Custom Dosage",
            cellRenderer: NoteCellRenderer,
            headerClass: columnsWidth.procedure==="small"?'rotate-header':"", 

            minWidth: determineWidth(columnsWidth.procedure),
          });
        }

        if (procedure.hasOwnProperty("note")) {
          baseColumn.children.push({
            field: `procedure_${procedure.procedure_name.replace(
              /\s+/g,
              "_"
            )}_note`,
            headerName: "Note",
            cellRenderer: NoteCellRenderer,
            headerClass: columnsWidth.procedure==="small"?'rotate-header':"", 

            minWidth: determineWidth(columnsWidth.procedure),
          });
        }

        return baseColumn;
      })
      .filter((column) => {
        // Filter out columns with no values if hideEmptyColumns is true
        if (hideEmptyColumns) {
          const hasData = orders.some((order) =>
            order.userProcedures.some(
              (proc) =>
                proc.procedure_name === column.headerName &&
                ((proc.selectedField && proc.selectedField !== "") ||
                  (proc.checked && proc.checked !== false))
            )
          );
          return hasData;
        }
        return true;
      });

    let dispensedColumns = sampleOrder.userdispensed
      .map((dispensed) => {
        const baseColumn = {
          headerName: dispensed.name,
          headerTooltip: `${dispensed.name}`,
          headerClass: columnsWidth.dispensed==="small"?'rotate-header':"", 
          minWidth: determineWidth(columnsWidth.dispensed),

          children: [
            {
              field: `dispensed_${dispensed.name.replace(/\s+/g, "_")}`,
              headerName: "Dosage",
              cellRenderer: DropdownCellRenderer,
              cellRendererParams: { values: dispensed.dosages },
              minWidth: determineWidth(columnsWidth.dispensed),
              headerClass: columnsWidth.dispensed==="small"?'rotate-header':"", 

            },
            {
              field: `dispensed_${dispensed.name.replace(
                /\s+/g,
                "_"
              )}_quantity`,
              headerName: "Quantity",
              cellRenderer: QuantityCellRenderer,
              minWidth: determineWidth(columnsWidth.dispensed),
              headerClass: columnsWidth.dispensed==="small"?'rotate-header':"", 

            },
          ],
        };

        if (dispensed.hasOwnProperty("note")) {
          baseColumn.children.push({
            field: `dispensed_${dispensed.name.replace(/\s+/g, "_")}_note`,
            headerName: "Note",
            cellRenderer: NoteCellRenderer,
            minWidth: determineWidth(columnsWidth.dispensed),
            headerClass: columnsWidth.dispensed==="small"?'rotate-header':"", 

          });
        }

        return baseColumn;
      })
      .filter((column) => {
        // Filter out columns with no values if hideEmptyColumns is true
        if (hideEmptyColumns) {
          const hasData = orders.some((order) =>
            order.userdispensed.some(
              (disp) =>
                disp.name === column.headerName &&
                (disp.selectedField || disp.quantity > 0)
            )
          );
          return hasData;
        }
        return true;
      });
    let labColumns = sampleOrder.userLabs
      .map((lab) => ({
        field: `lab_${lab.test_name.replace(/\s+/g, "_")}`,
        headerName: lab.test_name,
        headerTooltip: `${lab.test_name}`,
        cellRenderer: CheckboxCellRenderer,
        minWidth: determineWidth(columnsWidth.lab),
        headerClass: columnsWidth.lab==="small"?'rotate-header':"", 
        cellStyle:{textAlign:'center'}

      }))
      .filter((column) => {
        // Filter out columns with no values if hideEmptyColumns is true
        if (hideEmptyColumns) {
          const hasData = orders.some((order) =>
            order.userLabs.some(
              (labItem) =>
                labItem.test_name === column.headerName && labItem.checked
            )
          );
          return hasData;
        }
        return true;
      });

    if (!visibility.showNotes) {
      commonColumns = commonColumns.filter(
        (column) => column.headerName !== "Note"
      );
      procedureColumns = procedureColumns.map((column) => {
        return {
          ...column,
          children: column.children.filter(
            (child) => child.headerName !== "Note"
          ),
        };
      });
      dispensedColumns = dispensedColumns.map((column) => {
        return {
          ...column,
          children: column.children.filter(
            (child) => child.headerName !== "Note"
          ),
        };
      });
    }

    let filteredColumns = {
      common: commonColumns,
    };
    if (visibility.showProcedures) {
      filteredColumns.procedures = procedureColumns;
    }
    if (visibility.showDispensed) {
      filteredColumns.dispensed = dispensedColumns;
    }
    if (visibility.showLabs) {
      filteredColumns.labs = labColumns;
    }
    setAllColumnDefs(filteredColumns);

    setColumnDefs([
      ...commonColumns,
      ...procedureColumns,
      ...dispensedColumns,
      ...labColumns,
    ]);

    const newRowData = orders?.map((order) => {
      const rowData = {
        orderId: order._id,
        date: order.date,
        customName: order.custom || "",
        type: order.type || "Order",
        review: order.review || false,
        status: order.status || "",
        assignees: order.assignees || [],
        room: order.room || "",
        note: order.note || "",
      };

      order.userLabs.forEach((lab) => {
        rowData[`lab_${lab.test_name.replace(/\s+/g, "_")}`] = lab.checked;
      });

      order.userdispensed.forEach((dispensed) => {
        rowData[`dispensed_${dispensed.name.replace(/\s+/g, "_")}`] =
          dispensed.selectedField || "";
        rowData[`dispensed_${dispensed.name.replace(/\s+/g, "_")}_quantity`] =
          dispensed.quantity || 0;
        if (dispensed.hasOwnProperty("note")) {
          rowData[`dispensed_${dispensed.name.replace(/\s+/g, "_")}_note`] =
            dispensed.note || "";
        }
      });

      order.userProcedures.forEach((procedure) => {
        if (procedure.dosages && procedure.dosages.length > 0) {
          rowData[
            `procedure_${procedure.procedure_name.replace(/\s+/g, "_")}`
          ] = procedure.selectedField || "";
        } else {
          rowData[
            `procedure_${procedure.procedure_name.replace(/\s+/g, "_")}`
          ] = procedure.checked;
        }
        if (procedure.customDosage) {
          rowData[
            `procedure_${procedure.procedure_name.replace(
              /\s+/g,
              "_"
            )}_customDosage`
          ] = procedure.customDosage || "";
        }
        if (procedure.hasOwnProperty("note")) {
          rowData[
            `procedure_${procedure.procedure_name.replace(/\s+/g, "_")}_note`
          ] = procedure.note || "";
        }
      });

      return rowData;
    });

    setRowData(newRowData);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  function addNewOrderTemplate(obj) {
    if (template?._id) {
      const url = `/addTreatmentWithTemplate/${selectedPatient["Customer ID"]}/${obj.type}/${template?._id}`;
      axios
        .post(
          url,
          {}, // This is where you'd put the request body. If there's no body, you can leave it as an empty object
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        )
        .then((response) => {
          handleSelectPatient(selectedPatient._id);
          // patientDataTableRef.current.handleHideEmptyColumns();
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      const url = `/patient/order/new/${selectedPatient["Customer ID"]}/${obj.type}`;
      axios
        .post(
          url,
          { date: obj.date }, // This is where you'd put the request body. If there's no body, you can leave it as an empty object
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        )
        .then((response) => {
          handleSelectPatient(selectedPatient._id);
          // patientDataTableRef.current.handleHideEmptyColumns();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  function addNewOrder(type) {
    const url = `/patient/order/new/${selectedPatient["Customer ID"]}/${type.type}`;
    axios
      .post(
        url,
        {}, // This is where you'd put the request body. If there's no body, you can leave it as an empty object
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      )
      .then((response) => {
        handleSelectPatient(selectedPatient._id);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    if (allColumnDefs.common) {
      let filteredColumnDefs;
      switch (filter) {
        case "labs":
          filteredColumnDefs = [
            ...allColumnDefs.common,
            ...(allColumnDefs.labs || []),
          ];
          break;
        case "dispensed":
          filteredColumnDefs = [
            ...allColumnDefs.common,
            ...(allColumnDefs.dispensed || []),
          ];
          break;
        case "procedures":
          filteredColumnDefs = [
            ...allColumnDefs.common,
            ...(allColumnDefs.procedures || []),
          ];
          break;
        default:
          filteredColumnDefs = [
            ...allColumnDefs.common,
            ...(allColumnDefs.procedures || []),
            ...(allColumnDefs.dispensed || []),
            ...(allColumnDefs.labs || []),
          ];
      }
      setColumnDefs(filteredColumnDefs);
    }
  }, [filter, allColumnDefs]);

  useEffect(() => {
    if (selectedPatient) {
      const fetchOptions = async () => {
        // const assigneeOptions = await fetchAssigneeOptions();
        // const roomOptions = await fetchRoomOptions();

        setAllColumnDefs((prevState) => ({
          ...prevState,
          common: prevState.common?.map((col) => {
            if (col.field === "assignees") {
              return {
                ...col,
                cellRendererParams: {
                  ...col.cellRendererParams,
                  values: assigneeOptions,
                },
              };
            }
            if (col.field === "room") {
              return {
                ...col,
                cellRenderer: DropdownCellRenderer,
                cellRendererParams: { values: roomOptions },
              };
            }
            return col;
          }),
        }));
      };

      fetchOptions();
    }
  }, [selectedPatient]);

  useEffect(() => {
    const interval = setInterval(() => {
      handleRefresh();
    }, 15000);

    return () => clearInterval(interval);
  }, [selectedPatient]);
  

  const determineWidth = (columnSize) => {
  
    if (columnSize === "small") {
      return 100;
    } else if (columnSize === "medium") {
      return 130;
    } else {
      return 160;
    }
  };

  function splitByLastDash(columnName) {
    // Find the index of the last dash
    const lastIndex = columnName.lastIndexOf("-");

    // If there's no dash, or the dash is the first character, return an error or handle it as needed
    if (lastIndex <= 0) {
      console.error(
        "No dash found, or the dash is at the start of the string"
      );
      return columnName;
    }

    // Split the string into two parts
    const itemName = columnName.substring(0, lastIndex);
    const field = columnName.substring(lastIndex + 1);

    return [itemName, field];
  }

  const containerRef = useRef(null);
  const handleCellChange = (
    patientId,
    columnName,
    newValue,
    category,
    itemData,
    review
  ) => {
    let payload;
    if (category === "vitals") {
      // Make sure newValue is an array and directly assign it to updates
      payload = {
        category: category,
        updates: newValue, // Ensure this is correctly formatted as an array of vitals
      };
    } else if (category === "patientLabs") {
      payload = {
        category: category,
        updates: {
          checked: newValue,
          type: "item_number",
          value: itemData.item_number,
        },
      };
    } else if (category === "newpatientLab") {
      payload = {
        category: 'patientLabs',
        newData: {
          checked: newValue,
          isNew: true,
          test_name: itemData?.test_name,
        },
      };
    } else {
      // Handling for other categories remains the same
      const identifierKey =
        category === "userProcedures" ? "procedure_name" : "name";
      let [itemName, field] = splitByLastDash(columnName);

      if (category === "patientInfo") {
        field = columnName;
      }

      payload = {
        category: category,
        identifier: {
          key: identifierKey,
          value: itemName,
        },
        updates: {
          [field]: newValue,
        },
        newData:itemData,
      };
    }

    // Send the update request using axios
    axios
      .put(
        `/patient/order/update/${patientId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      )
      .then((response) => {
        handleRefresh()
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
      
  return (
    <div style={{ padding: 30 }}>
      <ToastContainer />
      <h1 className="text-2xl md:text-xl font-bold text-gray-800 tracking-wide">
        Treatment View (Under Development)
      </h1>
      <div className="my-4 flex items-center justify-between">
        <Box
          component={Paper}
          elevation={1}
          borderRadius={2}
          padding={3}
          marginY={2}
        >
          <div>
            <PatientSearch
              patients={patients}
              onSearch={handleSearch}
              onSelectPatient={handleSelectPatient}
              highlightedIndex={highlightedIndex}
              setHighlightedIndex={setHighlightedIndex}
              selectedPatientName={
                selectedPatient && selectedPatient["Full Name"]
              }
            />
            {selectedPatient && (
              <div className="ml-2 inline-block">
                <UpdatePatient patient={selectedPatient} />
              </div>
            )}
          </div>
          {selectedPatient && !isLoading && (
            <>
              <div className="flex items-center gap-4 mt-4">
                <Button
                  onClick={() => {
                    handleRefresh();
                  }}
                  disabled={!selectedPatient}
                  className="py-2 px-4 text-sm bg-blue-500 hover:!bg-blue-600 hover:!text-white text-white rounded-md focus:outline-none"
                  icon={<ReloadOutlined />}
                >
                  Refresh Treatments
                </Button>
                <Button
                  onClick={() => createNewOrder("Order")}
                  disabled={!selectedPatient}
                  className="py-2 px-4 text-sm bg-blue-500 hover:!bg-blue-600 hover:!text-white text-white rounded-md focus:outline-none"
                  icon={<PlusOutlined />}
                >
                  New Order
                </Button>

                <TreatmentTemplates
                  setTemplate={setTemplate}
                  addNewOrderTemplate={addNewOrderTemplate}
                  addNewOrder={addNewOrder}
                />
              </div>
            </>
          )}
        </Box>
        {selectedPatient && !isLoading && (
          <Box
            component={Paper}
            elevation={1}
            borderRadius={2}
            paddingY={2}
            paddingX={3}
            marginY={2}
          >
            <div className="flex items-center gap-4">
              {/* Vitals Read Only View */}
              <VitalsReadOnlyView patientId={selectedPatient["Customer ID"]} />
              {/* Supplements Modal */}
              <SupplementsModal
                selectedPatientId={selectedPatientId}
                selectedPatient={selectedPatient}
                toastifyToast={toastifyToast}
              />
            </div>
          </Box>
        )}
      </div>

      {selectedPatient && orders.length > 0 && !isLoading && (
        <div>
          <TableFilters
            visibility={visibility}
            setVisibility={setVisibility}
            checkboxes={checkboxes}
            setCheckboxes={setCheckboxes}
            filter={filter}
            handleFilterChange={handleFilterChange}
          />

          {rowData.length > 0 && orders.length > 0 && hasActiveLPD && (
            <TreatmentTable
              rowData={rowData}
              columnDefs={columnDefs}
              onCellValueChanged={handleCellValueChanged}
              columnsWidth={columnsWidth}
              key={selectedPatientId}
            />
          )}
        </div>
      )}

      {selectedPatient &&
        !isLoading &&
        (!hasActiveLPD) && (
          <div className="my-5 py-52 px-10 rounded-xl shadow-md flex items-center justify-center gap-3">
            <WarningFilled style={{ color: yellow[700], fontSize: "36px" }} />
            <Typography
              variant="h6"
              className="text-4xl font-bold"
              sx={{ fontWeight: 400 }}
            >
            {orders.length > 0 && (checkboxes.activeDispensed || checkboxes.activeLabs || checkboxes.activeProcedures) ?
              "No treatments match your current filters"
              :
              "No treatments found for the selected patient"}
            </Typography>
          </div>
        )}
      {!selectedPatient && !isLoading && (
        <>
          <div className=" my-5 py-52 px-10 rounded-xl shadow-md flex items-center justify-center gap-3">
            <Warning sx={{ color: yellow[700], fontSize: "36px" }} />
            <Typography
              variant="h6"
              className="text-4xl font-bold"
              sx={{ fontWeight: 400 }}
            >
              Please select a patient to see the treatments
            </Typography>
          </div>
        </>
      )}

      {isLoading && (
        <>
          <div className=" my-5  p-10 rounded-xl shadow-md flex flex-col items-center justify-center gap-3">
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={60}
              sx={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
            />
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={260}
              sx={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}
            />
          </div>
        </>
      )}
      
        {selectedPatient && <LPDPopup
          type={modalType}
          patientName={selectedPatient["Full Name"]}
          // allTreatments={orders}
          // setAllTreatments={setOrders}
          activeTreatment={activeTreatment}
          showModal={showModal}
          onCloseModal={()=>setShowModal(false)}
          // setShowModal={setShowModal}
          setModalType={setModalType}
          handleCellChange={handleCellChange}
          ref={{containerRef}}
          from="treatmentView"
        />}
      <EventsModal
        isPopoverOpen={isPopoverOpen}
        setIsPopoverOpen={setIsPopoverOpen}
        orders={orders}
        setUpdatedEvents={setUpdatedEvents}
        updatedEvents={updatedEvents}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
    </div>
  );
};

export default TreatmentView;
