import {
  Add,
  History,
  HistoryOutlined,
  Print,
  Remove,
  RemoveCircleOutline,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import { Button as AntdButton, DatePicker, message } from "antd";
import axios from "axios";
import jsPDF from "jspdf";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";

const SupplementsModal = ({
  selectedPatientId,
  selectedPatient,
  toastifyToast,
  from="",
  medications,
  setMedications,
  fetchAllMedications
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [updatedItems, setUpdatedItems] = useState([]);
  const [historicalSupplements, setHistoricalSupplements] = useState([]);
  const [openSupplements, setOpenSupplements] = useState(false);
  const [customFrequency, setCustomFrequency] = useState({});
  const [isCustomFrequencySelected, setIsCustomFrequencySelected] = useState(
    {}
  );
  const [printOptions, setPrintOptions] = useState({
    recommended: true,
    historical: true,
  });
  const [openPreview, setOpenPreview] = useState(false);
  const pdfDocRef = useRef(null);
  const [loadingSupplementId, setLoadingSupplementId] = useState(null);
  const [matchedSupplement, setMatchedSupplement] = useState({});
  const [selectedSupplements, setSelectedSupplements] = useState({});

  const [selectedPatientSH, setSelectedPatientSH] = useState();
  const [pdfUrl, setPdfUrl] = useState(null);
  const [supplements, setSupplements] = useState([]);
  const [uncheckedSupplements, setUncheckedSupplements] = useState([]);

  useEffect(()=>{
    console.log("******* Supplements", selectedSupplements)
  },[selectedSupplements])
  useEffect(() => {
    const initialSelectedSupplements = {};
    setHistoricalSupplements(selectedPatientSH?.HistoricalSupplements);
    selectedPatientSH?.RecommendedSupplements.forEach((recSupp) => {
      const matchingSupplement = supplements.find(
        (sup) => sup.supplement_name === recSupp.supplementName
      );
      if (matchingSupplement) {
        initialSelectedSupplements[matchingSupplement._id] = {
          checked: true,
          frequency: recSupp.frequency,
          inHistory: false,
          startDate: recSupp.startDate,
          endDate: recSupp.endDate,
        };
      }
    });
    
    selectedPatientSH?.HistoricalSupplements.forEach((histSupp) => {
      const matchingSupplement = supplements.find(
        (sup) => sup.supplement_name === histSupp.supplementName
      );
      if (matchingSupplement) {
        initialSelectedSupplements[matchingSupplement._id] = {
          checked: false,
          frequency: histSupp.frequency,
          inHistory: true,
          startDate: histSupp.startDate,
          endDate: histSupp.endDate,
        };
      }
    });

    setSelectedSupplements(initialSelectedSupplements);
  }, [selectedPatientSH, supplements]);

  useEffect(() => {
    function updateArrayWithIds(array, ids) {
      return array.map((obj) => {
        if (ids[obj._id]) {
          return {
            ...obj,
            frequencyOptions: ["1 dosage twice a day", ...obj.frequency],
            ...ids[obj._id],
          };
        } else {
          return {
            ...obj,
            checked: false,
            frequencyOptions: ["1 dosage twice a day", ...obj.frequency],
          };
        }
      });
    }
    const updatedArray = updateArrayWithIds(supplements, selectedSupplements);
    const sortedItems = sortCheckedItems([...updatedArray]);
    setUpdatedItems(sortedItems);
  }, [selectedSupplements, supplements]);

  const handleOpenSupplements = () => {
    if (selectedPatientId) {
      setOpenSupplements(true);
    } else {
      toastifyToast.info("Please select Patient");
    }
  };
  const handleRemoveFromHistory = (supplementId, supplementName) => {
    const updatedSelectedSupplements = { ...selectedSupplements };
    setLoadingSupplementId(supplementId);

    const supplement = updatedSelectedSupplements[supplementId];
    updatedSelectedSupplements[supplementId] = {
      ...updatedSelectedSupplements[supplementId],
      inHistory: false,
    };

    setSelectedSupplements(updatedSelectedSupplements);
    const matchedSupplements = [];
    selectedPatientSH?.HistoricalSupplements.forEach((histSupp) => {
      const matchingSupplement = supplements.find(
        (sup) => sup.supplement_name === histSupp.supplementName
      );
      if (matchingSupplement) {
        matchedSupplements.push(matchingSupplement);
      }
    });

    const matchedSupplement = matchedSupplements.find(
      (supp) => supp.supplement_name === supplementName
    );
    setMatchedSupplement(matchedSupplement);
    const newHistoricalSupplements = [
      {
        supplementName: supplements.find((sup) => sup._id === supplementId)
          .supplement_name,
        frequency: supplement.frequency,
        startDate: selectedSupplements[supplementId]?.startDate,
        endDate: selectedSupplements[supplementId]?.endDate,
      },
    ];

    const profileData = {
      patientId: selectedPatientId,
      supplementsToMove: newHistoricalSupplements,
    };

    axios
      .post("/MoveHistoricalToRecommended", profileData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      })
      .then((response) => {
        axios
          .get(
            `/patient-profiles/${selectedPatient["Customer ID"]}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
              },
            }
          )
          .then((response) => {
            setSelectedPatientSH(response.data);
            message.success("Removed from history successfully!");
            setLoadingSupplementId(null);
          })
          .catch((error) => {
            message.error("Failed to remove from history!");
            console.error(error);
            setLoadingSupplementId(null);
          });
      })
      .catch((error) => {
        message.error("Failed to remove from history!");
        console.error(error);
        setLoadingSupplementId(null);
      });
  };

  const handleCheckboxChangeNew = (supId,event) => {
    setSelectedSupplements((prev) => ({
      ...prev,
      [supId]: prev[supId]
        ? { ...prev[supId], checked: !prev[supId].checked }
        : { checked: true, frequency: "1 dosage twice a day" },
    }));
    if (from === 'emr') {
      const filterUncheckedSupplements = () => {
        if (event?.target?.checked) {
          return uncheckedSupplements;
        } else {          
          const isCurrentlyUnchecked = uncheckedSupplements.includes(supId);
          if (isCurrentlyUnchecked) {
            return uncheckedSupplements;
          } else {
            return [...uncheckedSupplements, supId];
          }
        }
      };    
      const newUncheckedSupplements = filterUncheckedSupplements();
      setUncheckedSupplements(newUncheckedSupplements);
    }
  };

  const handleMoveToHistory = (supplementId) => {
    const updatedSelectedSupplements = { ...selectedSupplements };
    const supplement = updatedSelectedSupplements[supplementId];

    const userTimezone= Intl.DateTimeFormat().resolvedOptions().timeZone;
    const newHistoricalSupplements = [
      ...historicalSupplements,
      {
        supplementName: supplements.find((sup) => sup._id === supplementId)
          .supplement_name,
        frequency: supplement.frequency,
        startDate: selectedSupplements[supplementId].startDate,
        endDate: moment().tz(userTimezone).format("YYYY-MM-DDT00:00:00.000[Z]")
      },
    ];

    updatedSelectedSupplements[supplementId] = {
      ...supplement,
      checked: false,
      inHistory: true,
      endDate: moment().tz(userTimezone).format("YYYY-MM-DDT00:00:00.000[Z]")
    };

    setSelectedSupplements(updatedSelectedSupplements);
    setHistoricalSupplements(newHistoricalSupplements);

    const profileData = {
      patientId: selectedPatientId,
      HistoricalSupplements: newHistoricalSupplements,
    };

    axios
      .post(
        "/Historical-RecommendedSupplements",
        profileData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      )
      .then((response) => {
        message.success("Moved to history successfully!");
      })
      .catch((error) => {
        message.error("Failed to move to history!");
        console.error(error);
      });
  };
  const handleGeneratePDF = () => {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "letter",
    });

    pdfDocRef.current = doc;

    const renderHeader = (doc) => {
      doc.setFontSize(10);

      doc.text("PATIENT", 10, 10);
      doc.setFont(undefined, "bold");
      doc.setFontSize(12);
      doc.text(`${selectedPatientSH["Full Name"]}`, 10, 15);
      doc.setFontSize(10);
      doc.setFont(undefined, "normal");
      doc.text(
        `DOB: ${moment(selectedPatientSH["DOB"]).format("MM/DD/YYYY")}`,
        10,
        20
      );
      doc.text(
        `AGE: ${moment().diff(moment(selectedPatientSH["DOB"]), "years")} yrs`,
        10,
        25
      );
      doc.text(`PRN: ${selectedPatientSH["Customer ID"]}`, 10, 30);

      doc.text("FACILITY", 75, 10);
      doc.setFont(undefined, "bold");
      doc.text("Sunridge Medical", 75, 15);
      doc.setFontSize(10);
      doc.setFont(undefined, "normal");
      doc.text("T (480) 659-9135", 75, 20);
      doc.text("F (480) 659-9197", 75, 25);
      doc.text("14200 N Northsight Blvd 160", 75, 30);
      doc.text("Scottsdale, AZ 85260", 75, 35);
      doc.text(`Print Date: ${moment().format("MM/DD/YYYY")}`, 135, 10);
    };

    renderHeader(doc);

    let startY = 40;

    const formatSupplements = (supplements, title) => {
      const tableColumn = ["Supplement Name", "Frequency"];
      const tableRows = [];

      supplements.forEach(([supplementId, supplementData]) => {
        const matchingSupplement = supplements.find(
          (sup) => sup._id === supplementId
        );
        if (matchingSupplement) {
          tableRows.push([
            matchingSupplement.supplement_name,
            supplementData.frequency,
          ]);
        }
      });

      return { tableColumn, tableRows, title };
    };

    if (printOptions.recommended) {
      const recommendedSupplements = Object.entries(selectedSupplements).filter(
        ([, value]) => value.checked && !value.inHistory
      );
      const { tableColumn, tableRows, title } = formatSupplements(
        recommendedSupplements,
        "Recommended Supplements"
      );

      doc.text(title, 15, startY);
      startY += 5;
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY,
        theme: "grid",
        headStyles: { fillColor: [220, 220, 220] },
      });
      startY = doc.lastAutoTable.finalY + 5;
    }

    if (printOptions.historical) {
      const historicalSupplements = Object.entries(selectedSupplements).filter(
        ([, value]) => value.inHistory
      );
      const { tableColumn, tableRows, title } = formatSupplements(
        historicalSupplements,
        "Historical Supplements"
      );

      doc.text(title, 15, startY);
      startY += 5;
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY,
        theme: "grid",
        headStyles: { fillColor: [220, 220, 220] },
      });
    }
    const pdfBlob = doc.output("blob");

    const url = URL.createObjectURL(pdfBlob);
    setPdfUrl(url);
    setOpenPreview(true);
  };

  const handleDownloadPDF = () => {
    if (pdfDocRef.current) {
      pdfDocRef.current.save("Supplements_Data.pdf");
    }
  };

  const handleFrequencyChange = (supId, frequency) => {
    setSelectedSupplements((prev) => ({
      ...prev,
      [supId]: { ...prev[supId], frequency },
    }));
  };

  const handleChangeSwitch = (index, checked) => {
    const updatedElements = [...updatedItems];
    updatedElements[index].checked = checked;
    setUpdatedItems(updatedElements);
    const supplement = updatedElements[index];
    if (
      checked &&
      supplement.frequency &&
      customFrequency[supplement._id].trim() !== ""
    ) {
      addFrequency(supplement, customFrequency[supplement._id]);
    } else if (
      !checked &&
      supplement.frequency &&
      customFrequency[supplement._id].trim() !== ""
    ) {
      removeFrequency(supplement, customFrequency[supplement._id]);
    }
  };

  const addFrequency = async (supplement, value) => {
    const matchedSupplement = supplements.find(
      (supp) => supp.supplement_name === supplement.supplement_name
    );

    try {
      const response = await axios.post(
        `/supplements/${matchedSupplement._id}/add-frequency`,
        {
          newFrequency: value,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      toastifyToast("Frequency added successfully", { type: "success" });
    } catch (error) {
      toastifyToast(
        `Error adding frequency: ${
          error.response?.data?.message || error.message
        }`,
        { type: "error" }
      );
    }
  };

  const removeFrequency = async (supplement, value) => {
    const matchedSupplement = supplements.find(
      (supp) => supp.supplement_name === supplement.supplement_name
    );

    try {
      const response = await axios.post(
        `/supplements/${matchedSupplement._id}/remove-frequency`,
        {
          frequencyToRemove: value,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      toastifyToast("Frequency removed successfully", { type: "success" });
    } catch (error) {
      toastifyToast(
        `Error adding frequency: ${
          error.response?.data?.message || error.message
        }`,
        { type: "error" }
      );
    }
  };

  const handleSubmitSupplements = (e) => {
    const selectedData = Object.entries(selectedSupplements)
      .filter(([, value]) => value.checked)
      .map(([key, value]) => {
        const supplement = supplements.find((sup) => sup._id === key);
        return {
          supplementName: supplement.supplement_name,
          frequency: value.frequency,
          startDate: value.startDate
        };
      });

    const profileData = {
      patientId: selectedPatientId,
      RecommendedSupplements: selectedData,
    };

    if(from==='emr'){
      const updatedMedications = [...medications];
      selectedData.forEach((newMed) => {
        const existingIndex = medications.findIndex(
          (med) =>
            med.supplementName === newMed.supplementName &&
            med.frequency === newMed.frequency
        );
    
        if (existingIndex !== -1) {
          updatedMedications[existingIndex] = {
            ...updatedMedications[existingIndex],
            startDate: newMed?.startDate || updatedMedications[existingIndex].startDate,
            endDate: newMed?.endDate || updatedMedications[existingIndex].endDate,
          };
        } else {
          updatedMedications.push(newMed);
        }
      });

      setMedications(updatedMedications);
     if(uncheckedSupplements.length>0) {    
        const selectedData = supplements
        .filter(supplement => uncheckedSupplements.includes(supplement._id))
        .map(supplement => ({
          supplementName: supplement.supplement_name,
          frequency: supplement.frequency, 
        }));      
      const supplementNames = selectedData.map(item => item.supplementName);
      axios
      .delete(
        `/emr/delete-medications?patientId=${selectedPatient._id}&supplementNames=${encodeURIComponent(JSON.stringify(supplementNames))}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      )
      .then((response) => {
        setMedications((prevMedications) => {
          return prevMedications.filter(
            (medication) => !response.data.deletedMedications.some(deleted => deleted.supplementName === medication.supplementName)
          );
        });
        if(from==='emr'){
          fetchAllMedications()
        }
        handleCloseSupplements();
      })
      .catch((error) => {
        console.error(error);
      });
     }
    }

    axios
      .post(
        "/Historical-RecommendedSupplements",
        profileData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      )
      .then((response) => {
        message.success("Data submitted successfully!");
        if(from==='emr'){
          fetchAllMedications()
        }
        handleCloseSupplements();
      })
      .catch((error) => {
        message.error("Failed to submit data!");
        console.error(error);
      });
  };

  const handleCloseSupplements = () => {
    setSearchTerm("");
    setCustomFrequency({});
    setIsCustomFrequencySelected({});
    setOpenSupplements(false);
  };

  const filteredItems =
    updatedItems &&
    updatedItems.filter((item) =>
      searchTerm && typeof searchTerm === "string"
        ? item.name.toLowerCase().includes(searchTerm.toLowerCase())
        : true
    );

  const fetchSupplementNames = async () => {
    try {
      const response = await axios.get(
        `/defaultSupplements`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      const supplements = response.data.map((supplement) => {
        if (!supplement.frequency || supplement.frequency.length === 0) {
          supplement.frequency = ["1 dosage twice a day"];
        }
        return supplement;
      });

      setSupplements(supplements);
    } catch (error) {
      console.error("Error fetching supplement names:", error);
      throw error;
    }
  };

  const fetchPatientProfile = async (customerId) => {
    try {
      const response = await axios.get(
        `/patient-profiles/${customerId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      setSelectedPatientSH(response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching patient profile:", error);
      throw error;
    }
  };

  const sortCheckedItems = (items) => {
    return items.slice().sort((a, b) => {
      if (a.checked === true && b.checked === false) return -1;
      if (a.checked === false && b.checked === true) return 1;
      const originalIndexA = supplements.findIndex(
        (item) => item.name === a.name
      );
      const originalIndexB = supplements.findIndex(
        (item) => item.name === b.name
      );
      return originalIndexA - originalIndexB;
    });
  };

  const handleChangeSupplementStartDate = (date, supplementId) => {
    setSelectedSupplements((prevState) => {
      return {
        ...prevState,
        [supplementId]: {
          ...prevState[supplementId],
          startDate: `${date}T00:00:00`, 
        },
      };
    });
  };
  
  return (
    <div>
      {from==="emr"?
      <AntdButton
      onClick={() => {
        fetchSupplementNames();
        fetchPatientProfile(selectedPatient["Customer ID"]);
        handleOpenSupplements();
      }}
      className="py-2 px-4 text-sm !bg-[#111828] hover:!bg-[#3d3d3d] hover:!text-white text-white rounded-md focus:outline-none"
    >
      Add Supplements
    </AntdButton>
      :<AntdButton
        onClick={() => {
          fetchSupplementNames();
          fetchPatientProfile(selectedPatient["Customer ID"]);
          handleOpenSupplements();
        }}
        className="py-2 px-4 text-sm bg-blue-500 hover:!bg-blue-600 hover:!text-white text-white rounded-md focus:outline-none"
      >
        Supplements
      </AntdButton>}
      
      <Dialog
        open={openSupplements}
        onClose={handleCloseSupplements}
        maxWidth="lg"
        fullWidth
      >
        <Box p={4} display="flex" flexDirection="column" height="80vh">
          <div className="flex justify-between">
            {" "}
            <DialogTitle>Add Supplements to Patient Treatment Plan</DialogTitle>
            <div className="flex justify-center items-center">
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={printOptions.recommended}
                      onChange={() =>
                        setPrintOptions((prev) => ({
                          ...prev,
                          recommended: !prev.recommended,
                        }))
                      }
                    />
                  }
                  label="Active"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={printOptions.historical}
                      onChange={() =>
                        setPrintOptions((prev) => ({
                          ...prev,
                          historical: !prev.historical,
                        }))
                      }
                    />
                  }
                  label="Historical"
                />
                <Tooltip
                  title={
                    !printOptions.recommended && !printOptions.historical
                      ? "Check Active or Historical option to Print"
                      : "Print"
                  }
                >
                  <span>
                    <IconButton
                      disabled={
                        !printOptions.recommended && !printOptions.historical
                      }
                      onClick={() => handleGeneratePDF()}
                    >
                      <Print />
                    </IconButton>
                  </span>
                </Tooltip>
                <div>
                  <Dialog
                    open={openPreview}
                    onClose={() => {
                      setOpenPreview(false);
                    }}
                    maxWidth="lg"
                    fullWidth
                    PaperProps={{
                      style: { height: "100%", padding: "10px 20px" },
                    }}
                  >
                    <DialogTitle>
                      PDF Preview
                      <button
                        className="py-2 px-4 text-sm bg-blue-500 hover:bg-gray-600 text-white rounded-md focus:outline-none"
                        onClick={handleDownloadPDF}
                        style={{ float: "right" }}
                      >
                        Download PDF
                      </button>
                    </DialogTitle>
                    <DialogContent
                      dividers
                      style={{ height: "calc(100% - 64px)" }}
                    >
                      {pdfUrl && (
                        <iframe
                          src={pdfUrl}
                          width="100%"
                          height="100%"
                          title="PDF Preview"
                          style={{ border: "none" }}
                        />
                      )}
                    </DialogContent>
                  </Dialog>
                </div>
              </div>
            </div>
          </div>

          <DialogContent
            style={{
              flexGrow: 1,
              overflowY: "scroll",
              padding: "1%",
            }}
          >
            <TextField
              label="Search"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                width: "100%",
                marginBottom: "20px",
              }}
            />
            <Grid container spacing={2}>
              {filteredItems.map((sup, index) => (
                <Grid item xs={12} key={index}>
                  <div className="flex items-center">
                    <Checkbox
                      checked={selectedSupplements[sup._id]?.checked || false}
                      disabled={
                        sup?.inHistory || loadingSupplementId === sup._id
                      }
                      onChange={(event) => handleCheckboxChangeNew(sup._id,event)}
                    />

                    {sup.supplement_name}
                    {selectedSupplements[sup._id]?.checked &&
                      sup.frequencyOptions?.length > 0 && (
                        <>
                          <FormControl
                            style={{
                              marginLeft: "10px",
                              minWidth: "200px",
                            }}
                          >
                            <Select
                              value={
                                isCustomFrequencySelected[sup._id]
                                  ? "Custom"
                                  : selectedSupplements[sup._id]?.frequency ||
                                    ""
                              }
                              onChange={(e) => {
                                const value = e.target.value;
                                if (value === "custom") {
                                  setIsCustomFrequencySelected((prev) => ({
                                    ...prev,
                                    [sup._id]: true,
                                  }));
                                  setCustomFrequency((prev) => ({
                                    ...prev,
                                    [sup._id]: "",
                                  }));
                                } else {
                                  setIsCustomFrequencySelected((prev) => ({
                                    ...prev,
                                    [sup._id]: false,
                                  }));
                                  handleFrequencyChange(sup._id, value);
                                }
                              }}
                              displayEmpty
                              renderValue={(selected) =>
                                selected ? (
                                  selected
                                ) : (
                                  <em>
                                    Select Recommended Supplement Frequency
                                  </em>
                                )
                              }
                              style={{
                                padding: "8px 12px",
                                height: "40px",
                              }}
                            >
                              {sup?.frequencyOptions?.length > 0 &&
                                sup.frequencyOptions.map((freq, freqIndex) => (
                                  <MenuItem key={freqIndex} value={freq}>
                                    {freq}
                                  </MenuItem>
                                ))}
                              <MenuItem value="custom">Custom</MenuItem>
                            </Select>
                          </FormControl>
                          {isCustomFrequencySelected[sup._id] && (
                            <>
                              <TextField
                                value={customFrequency[sup._id]}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setCustomFrequency((prev) => ({
                                    ...prev,
                                    [sup._id]: value,
                                  }));
                                  handleFrequencyChange(sup._id, value);
                                }}
                                onBlur={(event) => {
                                  if (sup.checked) {
                                    addFrequency(sup, event.target.value);
                                  }
                                }}
                                placeholder="Enter custom frequency"
                                style={{
                                  marginLeft: "10px",
                                  width: "200px",
                                }}
                                InputProps={{
                                  style: {
                                    height: "40px",
                                  },
                                }}
                                inputProps={{
                                  style: {
                                    padding: "8px 12px",
                                  },
                                }}
                              />
                              <Switch
                                checked={sup.checked}
                                className="bg-gray-300 ml-4 min-w-[100px]"
                                onChange={(checked) =>
                                  handleChangeSwitch(index, checked)
                                }
                                checkedChildren="Permanent"
                                unCheckedChildren="Temporary"
                              />
                            </>
                          )}

                          {selectedSupplements[sup._id]?.frequency &&
                            !selectedSupplements[sup._id]?.inHistory && (
                              <IconButton
                                onClick={() => handleMoveToHistory(sup._id)}
                                color="secondary"
                                title="Move to History"
                              >
                                <HistoryOutlined />
                                <Add
                                  style={{
                                    position: "absolute",
                                    top: 4,
                                    right: 0,
                                    fontSize: "0.5em",
                                    color: "green",
                                  }}
                                />
                              </IconButton>
                            )}
                        </>
                      )}
                    {selectedSupplements[sup._id]?.inHistory && (
                      <IconButton
                        onClick={() =>
                          handleRemoveFromHistory(sup._id, sup.supplement_name)
                        }
                        color="primary"
                        title="Remove from History"
                      >
                        <History />
                        <Remove
                          style={{
                            position: "absolute",
                            top: 4,
                            right: 0,
                            fontSize: "0.5em",
                            color: "red",
                          }}
                        />
                      </IconButton>
                    )}

                    <div className="flex items-center ml-3 gap-3">
                    {selectedSupplements[sup._id] && <div className="flex items-center gap-2"> <p className="text-green-500 font-semibold text-lg">S</p>  <DatePicker value={sup?.startDate? dayjs(sup.startDate):undefined} onChange={(e,newDate)=>handleChangeSupplementStartDate(newDate,sup._id)} allowClear={false}/> </div>}
                    {selectedSupplements[sup._id]?.inHistory && <div className="flex items-center gap-2"> <p className="text-red-500 font-semibold text-lg">E</p>  <p> {sup?.endDate? dayjs(sup?.endDate).format('MM-DD-YYYY'):undefined}</p> </div>}
                    </div>

                    {loadingSupplementId === sup._id && (
                      <Box
                        sx={{
                          display: "flex",
                          width: "24px",
                          height: "24px",
                          ml: 5,
                        }}
                      >
                        <CircularProgress size={24} />
                      </Box>
                    )}
                  </div>
                </Grid>
              ))}
            </Grid>
          </DialogContent>

          <DialogActions>
            <div className="flex justify-between w-full">
              <div className="flex justify-between items-center">
                <div className="text-red-500">Note:</div>
                <div className="ml-[5px]">
                  <IconButton color="secondary" title="Move to History">
                    <HistoryOutlined />
                    <Add
                      style={{
                        position: "absolute",
                        top: 4,
                        right: 0,
                        fontSize: "0.5em",
                        color: "green",
                      }}
                    />
                  </IconButton>
                  Add to History
                </div>
                <div className="ml-[5px]">
                  <IconButton color="primary" title="Remove from History">
                    <HistoryOutlined />
                    <RemoveCircleOutline
                      style={{
                        position: "absolute",
                        top: 4,
                        right: 0,
                        fontSize: "0.5em",
                        color: "red",
                      }}
                    />
                  </IconButton>
                  Remove from History
                </div>
              </div>
              <div>
                <Button
                  onClick={handleCloseSupplements}
                  sx={{
                    backgroundColor: "#3b82f6",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#3498db",
                    },
                  }}
                >
                  Close
                </Button>
                <Button
                  onClick={() => handleSubmitSupplements()}
                  sx={{
                    backgroundColor: "#3b82f6",
                    color: "white",
                    marginLeft: "10px",
                    "&:hover": {
                      backgroundColor: "#3498db",
                    },
                  }}
                >
                  Submit
                </Button>
              </div>
            </div>
          </DialogActions>
        </Box>
        {/* {toast && (
      <CustomToast
        message={toast.message}
        variant={toast.variant}
        customStyles={{ bottom: "80px" }}
      />
    )} */}
      </Dialog>
    </div>
  );
};

export default SupplementsModal;
